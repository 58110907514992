import React, { Component } from 'react';
import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';
import ExperimentalFeaturesList from '../components/ExperimentalFeaturesList';
import Flask from '../components/ExperimentalFeaturesList/Flask';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Experimental Chromium Web Platform Features"
          keywords={['']}
          pathname={this.props.location.pathname}
          description="Experimental web platform features are features that are not yet part of the official web platform
          specification but are available in Polypane"
          ogFileName="experimentalfeatures"
          ogCustomPath="/og-custom/"
        />
        <PageHeader>
          <Flask />
          <h1>Experimental Chromium Web Platform Features</h1>
        </PageHeader>
        <ExperimentalFeaturesList />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
