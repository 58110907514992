/* eslint-disable no-use-before-define */
import React from 'react';
import { css } from 'astroturf';
import json from './experimental-features.json';
import data, { currentChrome } from '../../data/releaseData';
import Link from '../link';

const currentChromiumVersion = currentChrome;

const uniqueEntries = Object.entries(json.uniqueExperimentalFeaturesPerRelease)
  .map(([release, features]) => [parseInt(release, 10), features])
  .sort((a, b) => b[0] - a[0]);

const allEntries = Object.entries(json.experimentalFeaturesPerRelease)
  .map(([release, features]) => [parseInt(release, 10), features])
  .sort((a, b) => b[0] - a[0]);

const List = () => {
  const [list, setList] = React.useState(uniqueEntries);
  const [search, setSearch] = React.useState('');
  const [showAll, setShowAll] = React.useState(false);

  const featureList = list
    .filter((x) => {
      if (showAll) {
        return true;
      }
      return x[0] <= currentChromiumVersion;
    })
    .map(([release, features]) => {
      const featuresWithDescription = [];
      const featuresWithoutDescription = [];

      features
        .filter((x) => (search !== '' ? x.toLowerCase().indexOf(search.toLowerCase()) > -1 : true))
        .forEach((feature) => {
          if (json.allFeaturesInfo?.[feature]?.summary || !!alternativeContent[feature]) {
            featuresWithDescription.push(
              <li key={feature}>
                <div>
                  <b>
                    <IconForFeature name={feature} />
                    {camelCaseToSpaces(feature)}
                  </b>
                  <hr />
                  <div className={styles.info}>
                    <code>{feature}</code>
                    {json.allFeaturesInfo?.[feature]?.url ? (
                      <a href={json.allFeaturesInfo?.[feature]?.url} target="_blank" rel="noreferrer">
                        chromestatus.com <ExternalLink />
                      </a>
                    ) : null}
                  </div>
                </div>
                <p>{json.allFeaturesInfo?.[feature]?.summary || alternativeContent[feature]}</p>
              </li>
            );
          } else {
            featuresWithoutDescription.push(
              <div>
                <i>
                  <IconForFeature name={feature} /> {camelCaseToSpaces(feature)}
                </i>
                <hr />
                <div className={styles.info}>
                  <code>{feature}</code>
                  {json.allFeaturesInfo?.[feature]?.url ? (
                    <a href={json.allFeaturesInfo?.[feature]?.url} target="_blank" rel="noreferrer">
                      chromestatus.com <ExternalLink />
                    </a>
                  ) : null}
                </div>
              </div>
            );
          }
        });

      return (
        <div className={styles.feature}>
          <h2>
            {list === uniqueEntries ? 'Added in ' : ''}
            Chromium {release} {release === currentChromiumVersion + 2 ? <span className={styles.badge}>Dev</span> : ''}
            {release === currentChromiumVersion + 1 ? <span className={styles.badge}>Beta</span> : ''}
            {release === currentChromiumVersion ? <span className={styles.badge}>current</span> : ''}
            {data.find((x) => x.chromiumVersion === release) ? (
              <span className={styles.badge}>Polypane {data.find((x) => x.chromiumVersion === release).version}</span>
            ) : (
              ''
            )}
          </h2>
          {featuresWithDescription.length > 0 || featuresWithoutDescription.length > 0 ? (
            <ul>
              {featuresWithDescription}
              <li>
                <b>
                  {list === uniqueEntries ? 'New features ' : 'Features'} in Chromium {release} with missing description
                </b>
                <br />
                {featuresWithoutDescription}
              </li>
            </ul>
          ) : (
            <p className={styles.notfound}>
              No{list === uniqueEntries ? ' new ' : ' '}experimental features found
              {search !== '' ? ` for search term "${search}"` : ''}.
            </p>
          )}
        </div>
      );
    });

  return (
    <div className="widePage childPadding">
      <div className={styles.cols}>
        <p>
          Experimental web platform features are not yet part of the official web platform specifications and are marked
          as "experimental" in Chromium.
        </p>
        <p>
          <b>
            Polypane <Link to="/docs/experimental-chromium-features/">ships with these features</Link> (and more)
            enabled by default.
          </b>{' '}
          Other Chromium browsers let you enable these on a "flags" settings page.
        </p>
      </div>
      <div className={styles.settings}>
        <input
          type="search"
          placeholder="Filter features..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div>
          <label className={styles.allfeatures}>
            <input
              type="checkbox"
              checked={list === uniqueEntries}
              onChange={() => setList(list === uniqueEntries ? allEntries : uniqueEntries)}
            />{' '}
            Show only features introduced in each version
          </label>
          <label className={styles.showall}>
            <input type="checkbox" checked={showAll} onChange={() => setShowAll(!showAll)} /> Show upcoming Chromium
            versions
          </label>
        </div>
      </div>

      {featureList}
    </div>
  );
};
export default List;

const camelCaseToSpaces = (str) =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .replace('H T M L', 'HTML')
    .replace('H T T P', 'HTTP')
    .replace('J S O N', 'JSON')
    .replace('Web G L', 'WebGL')
    .replace('Web G P U', 'WebGPU')
    .replace('Layout N G', 'LayoutNG')
    .replace('C S S', 'CSS')
    .replace('S V G', 'SVG')
    .replace('U R L', 'URL')
    .replace('V T T', 'VTT')
    .replace('L C P', 'LCP')
    .replace('A O M', 'AOM')
    .replace('D O M', 'DOM')
    .replace('A P I', 'API')
    .replace('U K M', 'UKM')
    .replace('R T C', 'RTC')
    .replace('R G B', 'RGB')
    .replace('H D R', 'HDR')
    .replace('X H R', 'XHR')
    .replace('J S', 'JS ')
    .replace('O S', 'OS ')
    .replace('O M', 'OM ')
    .replace('B G', 'BG ')
    .replace('X R', 'XR ')
    .replace('G C', 'GC');

const ExternalLink = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-external-link"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentcolor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
    <line x1="10" y1="14" x2="20" y2="4" />
    <polyline points="15 4 20 4 20 9" />
  </svg>
);

const styles = css`
  .settings {
    border-radius: 0.5rem;
    max-width: 60rem;
    position: sticky;
    top: 80px;
    z-index: 2;
    background: #f7faff;
    box-shadow: 0 0px 4px 4px #f7faff;

    input[type='search'] {
      outline: none;
      background: #fff;
      display: block;
      font-size: clamp(16px, calc(16px + 1vw), 1.5rem);
      padding: 1rem;
      width: 100%;
      border-radius: 5px;
      border: 0;
      box-shadow:
        rgba(0, 0, 0, 0.025) 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 0px,
        rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
      font-family: monospace;
      min-height: 68px;
    }
    div {
      padding: 0.5em 0;
      display: flex;
      justify-content: flex-start;
      gap: 0.25rem 2rem;
      flex-wrap: wrap;
    }
  }

  .feature {
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      position: sticky;
      top: 190px;
      background: #f7faff;
      z-index: 1;
      box-shadow: 0 -8px 12px 20px #f7faff;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        div {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          flex-wrap: wrap;
        }

        b {
          display: block;
          line-height: 1;
        }
        svg {
          vertical-align: top;
          width: 1em;
          height: 1em;
        }

        i {
          display: block;
          line-height: 1;
        }

        hr {
          flex-grow: 1;
          background: #107db5;
          height: 1px;
          border: 0;
          opacity: 0.5;
        }

        p {
          white-space: pre-wrap;
          word-break: break-word;
          padding: 1rem 2rem 0;
        }
        p em {
          opacity: 0.7;
        }
      }
    }

    .info {
      display: inline flex;
      background: rgb(16, 125, 181);
      color: #fff;
      padding: 0.33rem;
      border-radius: 8px;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      font-size: 0.8rem;
      flex-shrink: 0;
      margin: 0;
      flex-wrap: wrap;
      max-width: 100%;

      code {
        background: #0007;
        line-height: 1;
        border-radius: 4px;
        padding: 5px;
        font-size: 0.9em;
        white-space: pre-wrap;
        word-break: break-all;
      }
      a {
        color: inherit;
        &:hocus {
          text-decoration: underline;
        }
      }
      svg {
        vertical-align: text-bottom;
        width: 1em;
        height: 1em;
      }
    }
  }

  .badge {
    display: inline-block;
    font-size: 0.9rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
    margin-left: 0.5rem;
    vertical-align: baseline;
  }

  .notfound {
    font-style: italic;
    opacity: 0.7;
  }

  .cols {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    @media (min-width: 40rem) {
      flex-direction: row;
      gap: 4rem;

      p {
        flex: 1 1 45%;
      }
    }
  }
`;

const IconForFeature = ({ name }) => {
  const lowercase = name.toLowerCase();
  if (lowercase.startsWith('clipboard')) {
    return <ClipBoard />;
  }
  if (
    ['css', 'aligncontentforblocks', 'stylableselect', 'displaycontents', 'nestedtoplayer'].some((x) =>
      lowercase.startsWith(x)
    )
  ) {
    return <CSS />;
  }
  if (lowercase.startsWith('html') || lowercase.startsWith('detailsstyling') || lowercase.startsWith('element')) {
    return <HTML />;
  }
  if (lowercase.startsWith('fetch')) {
    return <Fetch />;
  }
  if (lowercase.startsWith('image') || lowercase.startsWith('autosizelazyloadedimages')) {
    return <Image />;
  }
  if (lowercase.startsWith('json') || lowercase.startsWith('prettyprintjson')) {
    return <Code />;
  }
  if (lowercase.startsWith('media')) {
    return <Media />;
  }
  if (lowercase.startsWith('context')) {
    return <Pointer />;
  }
  if (lowercase.startsWith('device')) {
    return <Device />;
  }
  if (lowercase.startsWith('file')) {
    return <File />;
  }
  if (lowercase.startsWith('font') || lowercase.startsWith('text')) {
    return <Font />;
  }
  if (lowercase.startsWith('form')) {
    return <Form />;
  }
  if (lowercase.startsWith('fullscreen')) {
    return <Fullscreen />;
  }
  if (lowercase.startsWith('payment')) {
    return <Payment />;
  }
  if (lowercase.startsWith('keyboard')) {
    return <Keyboard />;
  }
  if (lowercase.startsWith('accessibility')) {
    return <Accessibility />;
  }
  if (lowercase.startsWith('storage') || lowercase.startsWith('database')) {
    return <Storage />;
  }
  if (lowercase.startsWith('password')) {
    return <Password />;
  }
  if (lowercase.startsWith('highlight')) {
    return <Highlight />;
  }
  if (lowercase.startsWith('page')) {
    return <Page />;
  }
  if (lowercase.startsWith('user')) {
    return <User />;
  }
  if (
    lowercase.startsWith('prefers') ||
    lowercase.startsWith('invertedcolors') ||
    lowercase.startsWith('webpreferences')
  ) {
    return <Prefers />;
  }
  if (lowercase.startsWith('webauthentication')) {
    return <Authenticate />;
  }
  if (lowercase.startsWith('mouse')) {
    return <Mouse />;
  }
  if (lowercase.startsWith('compute')) {
    return <Compute />;
  }
  if (lowercase.startsWith('webcodec') || lowercase.startsWith('video')) {
    return <Video />;
  }
  if (lowercase.startsWith('intersect')) {
    return <Intersect />;
  }
  if (lowercase.startsWith('backdrop')) {
    return <Backdrop />;
  }
  if (lowercase.startsWith('navigation') || lowercase.startsWith('navigate')) {
    return <Navigation />;
  }

  if (lowercase.startsWith('observ') || lowercase.startsWith('visible')) {
    return <Eye />;
  }

  if (lowercase.startsWith('webassembly')) {
    return <Assembly />;
  }

  if (lowercase.startsWith('clippath')) {
    return <Scissors />;
  }
  if (lowercase.startsWith('boundary')) {
    return <Bounds />;
  }
  if (lowercase.startsWith('url')) {
    return <Url />;
  }
  return null;
};

const ClipBoard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
    <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
  </svg>
);

const CSS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
    <path d="M8 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0" />
    <path d="M11 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
    <path d="M17 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
  </svg>
);

const HTML = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M7 8l-4 4l4 4" />
    <path d="M17 8l4 4l-4 4" />
    <path d="M14 4l-4 16" />
  </svg>
);

const Fetch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
    <path d="M12 13l0 9" />
    <path d="M9 19l3 3l3 -3" />
  </svg>
);

const Media = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
    <path d="M16 3l-4 4l-4 -4" />
  </svg>
);

const Pointer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z" />
  </svg>
);

const Device = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z" />
    <path d="M11 4h2" />
    <path d="M12 17v.01" />
  </svg>
);

const File = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2zm-7 -7h.01m3.99 0h.01" />
    <path d="M10 17a3.5 3.5 0 0 0 4 0" />
  </svg>
);

const Font = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 20l3 0" />
    <path d="M14 20l7 0" />
    <path d="M6.9 15l6.9 0" />
    <path d="M10.2 6.3l5.8 13.7" />
    <path d="M5 20l6 -16l2 0l7 16" />
  </svg>
);

const Form = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3" />
    <path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3" />
    <path d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7" />
    <path d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1" />
    <path d="M17 12h.01" />
    <path d="M13 12h.01" />
  </svg>
);

const Fullscreen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16 4l4 0l0 4" />
    <path d="M14 10l6 -6" />
    <path d="M8 20l-4 0l0 -4" />
    <path d="M4 20l6 -6" />
    <path d="M16 20l4 0l0 -4" />
    <path d="M14 14l6 6" />
    <path d="M8 4l-4 0l0 4" />
    <path d="M4 4l6 6" />
  </svg>
);

const Payment = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
    <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
  </svg>
);

const Keyboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 6m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z" />
    <path d="M6 10l0 .01" />
    <path d="M10 10l0 .01" />
    <path d="M14 10l0 .01" />
    <path d="M18 10l0 .01" />
    <path d="M6 14l0 .01" />
    <path d="M18 14l0 .01" />
    <path d="M10 14l4 .01" />
  </svg>
);

const Accessibility = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
    <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
  </svg>
);

const Storage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0" />
    <path d="M4 6v6a8 3 0 0 0 16 0v-6" />
    <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
  </svg>
);

const Password = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 12l8 -4.5" />
    <path d="M12 12v9" />
    <path d="M12 12l-8 -4.5" />
    <path d="M12 12l8 4.5" />
    <path d="M12 3v9" />
    <path d="M12 12l-8 4.5" />
  </svg>
);

const Highlight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 19h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
    <path d="M12.5 5.5l4 4" />
    <path d="M4.5 13.5l4 4" />
    <path d="M21 15v4h-8l4 -4z" />
  </svg>
);

const Page = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 20h-11a3 3 0 0 1 0 -6h11a3 3 0 0 0 0 6h1a3 3 0 0 0 3 -3v-11a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v8" />
  </svg>
);

const User = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
  </svg>
);

const Prefers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 12a9 9 0 1 0 -8.983 9" />
    <path d="M18.001 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M18.001 14.5v1.5" />
    <path d="M18.001 20v1.5" />
    <path d="M21.032 16.25l-1.299 .75" />
    <path d="M16.27 19l-1.3 .75" />
    <path d="M14.97 16.25l1.3 .75" />
    <path d="M19.733 19l1.3 .75" />
    <path d="M9 10h.01" />
    <path d="M15 10h.01" />
    <path d="M9.5 15c.658 .64 1.56 1 2.5 1" />
  </svg>
);

const Authenticate = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
    <path d="M15 9h.01" />
  </svg>
);

const Mouse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z" />
    <path d="M12 7l0 4" />
  </svg>
);

const Compute = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M5 5m0 1a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1z" />
    <path d="M9 9h6v6h-6z" />
    <path d="M3 10h2" />
    <path d="M3 14h2" />
    <path d="M10 3v2" />
    <path d="M14 3v2" />
    <path d="M21 10h-2" />
    <path d="M21 14h-2" />
    <path d="M14 21v-2" />
    <path d="M10 21v-2" />
  </svg>
);

const Video = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
    <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
  </svg>
);

const Intersect = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M8 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
    <path d="M4 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
    <path d="M9 15l6 -6" />
  </svg>
);

const Backdrop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 8l4 -4" />
    <path d="M14 4l-10 10" />
    <path d="M4 20l16 -16" />
    <path d="M20 10l-10 10" />
    <path d="M20 16l-4 4" />
  </svg>
);

const Navigation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 18.5l7.265 2.463c.196 .077 .42 .032 .57 -.116a.548 .548 0 0 0 .134 -.572l-7.969 -17.275l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463" />
  </svg>
);

const Eye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
  </svg>
);

const Assembly = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
    <path d="M15.5 9.422c.312 .18 .503 .515 .5 .876v3.277c0 .364 -.197 .7 -.515 .877l-3 1.922a1 1 0 0 1 -.97 0l-3 -1.922a1 1 0 0 1 -.515 -.876v-3.278c0 -.364 .197 -.7 .514 -.877l3 -1.79c.311 -.174 .69 -.174 1 0l3 1.79h-.014z" />
  </svg>
);

const Scissors = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M6 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M8.6 8.6l10.4 10.4" />
    <path d="M8.6 15.4l10.4 -10.4" />
  </svg>
);

const Code = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M7 4a2 2 0 0 0 -2 2v3a2 3 0 0 1 -2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2" />
    <path d="M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0 -2 3v3a2 2 0 0 1 -2 2" />
  </svg>
);

const Image = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M15 8h.01" />
    <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
    <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
    <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
  </svg>
);

const Bounds = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 7l16 0" />
    <path d="M4 17l16 0" />
    <path d="M7 4l0 16" />
    <path d="M17 4l0 16" />
  </svg>
);

const Url = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-clipboard"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 15l6 -6" />
    <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464" />
    <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463" />
  </svg>
);

const alternativeContent = {
  StylableSelect: `Allows <button> and <datalist> in <selectlist> which replace the default button and listbox respectively, as well as introducing a new <selectedoption> element that shows the chosen value.`,
};
